<template>
  <div id="app" class="app bg-white">
    <router-link :to="{ name: 'AppContainer' }"></router-link>
    <router-view></router-view>
    <TransitionRoot
      as="div"
      class="flex place-content-center fixed bottom-8 w-full"
      :show="errorMessage != null"
      enter="transition-opacity ease-in-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity ease-in-out duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <toast
        v-if="errorMessage != null"
        class=" w-2/3 max-w-lg m-4 shadow z-50"
        :message="errorMessage"
        @close="closeErrorToast()"
      />
    </TransitionRoot>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import {
  TransitionRoot,
} from '@headlessui/vue';
import Toast from './components/modals/Toast.vue';
export default {
  name: 'App',
  components: {
    TransitionRoot,
    Toast,
  },
  setup() {
    const store = useStore();
    const errorMessage = computed(() => store.getters.errorMessage);

    function closeErrorToast() {
      store.dispatch('setError', null);
    }
  
    return {
      errorMessage,
      closeErrorToast,
    };
  }
};
</script>

<style>
/* Otherwise the default styling applies */
@import './assets/index.css';

/* Global overrides to Widgets styles */

@import url("https://use.typekit.net/rqj8ibu.css"); /* import the brand fonts */

</style>

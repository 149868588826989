class DashboardSettingMapping {
  shortLabel: string
  fullLabel: string
  valueMapper: ValueMapper

  constructor (shortLabel: string, fullLabel: string, valueMapper: ValueMapper) {
    this.shortLabel = shortLabel;
    this.fullLabel = fullLabel;
    this.valueMapper = valueMapper;
  }
}

type ValueMapper = (value: string | number | boolean) => string

/* If integer simply return a whole number, 
 * otherwise sets precision to two decimal places
 * and remove trailing zeros 
**/
function returnFormattedNumber(value: number): string {
  return Number.isInteger(value) ? value.toFixed().toString() : parseFloat(value.toFixed(2)).toString();
}

function returnString(value: string | number | boolean): string {
  if (typeof value === 'number') {
    return returnFormattedNumber(value);
  }
  return `${value.toString().charAt(0).toUpperCase()}${value.toString().slice(1)}`;
}

function returnYesNo(value: string | number | boolean): string {
  return value ? 'Yes' : 'No';
}

function returnUpperCased(value: string | number | boolean): string {
  const elements = value.toString().split(/(\d+)/);
  if (elements.length > 1) {
    return `${elements.join('\u00A0').toUpperCase()}`;
  }
  return `${value}`.toUpperCase();
}

function returnPercentage(value: string | number | boolean): string {
  return `${(Number(value) * 100).toFixed(0)}%`;
}

function returnTime(value: string | number | boolean): string {
  const hours = Math.floor(Number(value) / 60);
  const minutes = Number(value) % 60;
  if (hours === 0) {
    return `${minutes}\u00A0min`;
  }
  if (minutes === 0) {
    return `${hours}\u00A0${hours === 1 ? 'hr' : 'hrs'}`;
  }
  return `${hours}\u00A0${hours === 1 ? 'hr' : 'hrs'} ${minutes}\u00A0min`;
}

export const DASHBOARD_LABEL_CONSTANTS: { [key: string]: DashboardSettingMapping } = {
  'air_changes_per_hour': new DashboardSettingMapping('Air-cleaning devices (ACH)', 'Air-cleaning devices (ACH)', returnString),
  'uvgi': new DashboardSettingMapping('UVGI', 'Ultraviolet germicidal irradiation', returnYesNo),
  'mechanical_filter': new DashboardSettingMapping('Filtration', 'Mechanical filter', returnUpperCased),
  'oa_exchange_fraction': new DashboardSettingMapping('Outside air (%)', 'Outside air (%)', returnPercentage),
  'universal_mask_type': new DashboardSettingMapping('Mask type', 'Universal mask type', returnString),
  'mask_use_rate': new DashboardSettingMapping('Mask use (%)', 'Mask use (%)', returnPercentage),
  'occupancy_duration': new DashboardSettingMapping('Duration', 'Occupancy duration', returnTime),
  'capacity_limit_fraction': new DashboardSettingMapping('Occupancy (%)', 'Capacity limit fraction', returnPercentage),
  'relative_humidity': new DashboardSettingMapping('Humidity', 'Relative Humidity', returnPercentage),
  'vaccine_mandate': new DashboardSettingMapping('COVID vax mandate', 'COVID vax mandate', returnYesNo),
  'testing_program': new DashboardSettingMapping('COVID testing', 'COVID testing', returnYesNo),
  'distancing': new DashboardSettingMapping('Distancing', 'Distancing', returnYesNo),
  'vaccine_mandate_flu': new DashboardSettingMapping('Flu vax mandate', 'Flu vax mandate', returnYesNo),
};
import { User } from '@/models/user-permission';
import { ParticleOneUserPermission } from '@/network/models/ParticleOneUserPermission';
import { Module } from 'vuex';
import userAPIService from '@/network/services/user';

interface UserState {
  users: ParticleOneUserPermission[]
}

const user: Module<any, any> = {
  state: {
    users: []
  } as UserState,
  getters: {
    users(state: UserState): User[] {
      return state.users.map(permission => new User(permission));
    }
  },
  actions: {
    async addUserPermission({ commit }, user: any) {
      const request = {
        user_id: user['oid'],
        building_id: user['buildingId'],
        space_id: user['spaceId'],
        policy: user['policy']
      };
      await userAPIService.addUserPermission(request);
    },
    async removeUserPermission({ commit }, user: any) {
      const request = {
        user_id: user['oid'],
        building_id: user['buildingId'],
        space_id: user['spaceId'],
        policy: user['policy']
      };
      await userAPIService.removeUserPermission(request);
    },
    async getAllUsers({ commit }, params) {
      commit('updateUsers', []);
      const users = await userAPIService.getAllUserPermissions(params);
      commit('updateUsers', users);
    }
  },
  mutations: {
    updateUsers(state: UserState, payload: ParticleOneUserPermission[]) {
      state.users = payload;
    }
  }
};

export default user;
interface ParticleOneCreateSpaceRequest {
  id?: number | null
  building_id?: number | null
  created_at?: string | null
  updated_at?: string | null
  name?: string | null
  description?: string | null
  category?: ParticleOneSpaceCategory | null
  sub_category?: ParticleOneSubcategory | null
  units?: ParticleOneUnit | null
  floor_area?: number | null
  ceiling_height?: number | null
  peak_occupancy?: number | null
  occupant_duration_minutes?: number | null
  occupant_schedule?: ParticleOneOccupancySchedule | null
  occupant_profile?: ParticleOneOccupantProfile | null
  activity?: ParticleOneActivity | null
  mask_type?: ParticleOneMaskType | null
  mask_use_rate?: number | null
  social_distancing?: boolean | null
  vaccine_mandate?: boolean | null
  vaccine_mandate_booster?: boolean | null
  vaccine_mandate_flu?: boolean | null
  background_vaccination_rate?: number | null
  background_vaccination_rate_booster?: number | null
  background_vaccination_rate_flu?: number | null
  testing_program?: boolean | null
  enhanced_cleaning?: boolean | null
  testing_technology?: ParticleOneTestingTechnology | null
  program?: ParticleOneTestingProgram | null
  testing_cadence?: ParticleOneTestingCadence | null
  testing_unvaccinated_only?: boolean | null
  supply_airflow_rate?: number | null
  outside_air_rate?: number | null
  mechanical_system?: ParticleOneMechanicalSystemType | null
  filtration?: ParticleOneMechanicalFilterType | null
  temperature?: number | null
  relative_humidity_rate?: number | null
  barriers?: boolean | null
  air_cleaning_devices?: boolean | null
  total_clean_air_delivery_rate?: number | null
  upper_room_uvgi?: boolean | null
}

type ParticleOneSubcategory = ParticleOneCorrectionalSpaceSubcategory | ParticleOneEducationalSpaceSubcategory | ParticleOneFoodAndBeverageSpaceSubcategory | ParticleOneHotelSpaceSubcategory | ParticleOneBankSubcategory | ParticleOneManufacturingSubcategory | ParticleOneOfficeSubcategory | ParticleOneOutpatientHealthcareFacilitySubcategory | ParticleOnePublicAssemblySubcategory | ParticleOneRetailSubcategory | ParticleOneRetailSubcategory | ParticleOneSportsAndEntertainmentSubcategory | ParticleOneResidentialSubcategory | ParticleOneTransitSubcategory

enum ParticleOneSpaceCategory {
  'Correctional Facilities' = 'correctional_facilities',
  'Educational Facilities' = 'educational_facilities',
  'Food and Beverage Service' = 'food_and_beverage_service',
  'Hotels/Motels/Resorts/Dormitories' = 'hotels_motels_resorts_dormitories',
  'Bank' = 'bank',
  'Manufacturing/Industrial' = 'manufacturing_industrial',
  'Office' = 'office',
  'Outpatient Healthcare Facilities' = 'outpatient_healthcare_facilities',
  'Public Assembly Spaces' = 'public_assembly_spaces',
  'Retail' = 'retail',
  'Sports and Entertainment' = 'sports_and_entertainment',
  'Residential' = 'residential',
  'Pharmacy' = 'pharmacy',
  'Transit' = 'transit'
}

enum ParticleOneCorrectionalSpaceSubcategory {
  'Booking/waiting' = 'booking_waiting',
  'Cell' = 'cell',
  'Cells with toilet' = 'cells_with_toilet',
  'Dayroom' = 'dayroom',
  'Guard stations' = 'guard_stations',
  'Public Washroom/Restroom' = 'public_washroom_restroom',
  'Private Washroom/Restroom' = 'private_washroom_restroom',
  'Elevator' = 'elevator',
}

enum ParticleOneEducationalSpaceSubcategory {
  'Art classsroom' = 'art_classsroom',
  'Classrooms (ages 5 to 8)' = 'classrooms_ages_5_to_8',
  'Classrooms (age 9 plus)' = 'classrooms_age_9_plus',
  'Computer lab' = 'computer_lab',
  'Daycare sickroom' = 'daycare_sickroom',
  'Daycare (through age 4)' = 'daycare_through_age_4',
  'Lecture classroom' = 'lecture_classroom',
  'Lecture hall (fixed seats)' = 'lecture_hall_fixed_seats',
  'Libraries' = 'libraries',
  'Media center' = 'media_center',
  'Multiuse assembly' = 'multiuse_assembly',
  'Music/theater/dance' = 'music_theater_dance',
  'Science laboratories' = 'science_laboratories',
  'University/college laboratories' = 'university_college_laboratories',
  'Wood/metal shop' = 'wood_metal_shop',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneFoodAndBeverageSpaceSubcategory {
  'Bars, cocktail lounges' = 'bars_cocktail_lounges',
  'Cafeteria/fast-food dining' = 'cafeteria_fast_food_dining',
  'Kitchen (cooking)' = 'kitchen_cooking',
  'Restaurant dining rooms' = 'restaurant_dining_rooms',
  'Occupiable storage rooms for liquids or gels' = 'occupiable_storage_rooms_for_liquids_or_gels',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneHotelSpaceSubcategory {
  'Bedroom/living room' = 'barracks_sleeping_areas',
  'Laundry rooms, central' = 'laundry_rooms_central',
  'Laundry rooms within dwelling units' = 'laundry_rooms_within_dwelling_units',
  'Lobbies/prefunction' = 'lobbies_prefunction',
  'Multipurpose assembly' = 'multipurpose_assembly',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneBankSubcategory {
  'Banks or bank lobbies' = 'banks_or_bank_lobbies',
  'Bank vaults/safe deposit' = 'bank_vaults_safe_deposit',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneManufacturingSubcategory {
  'Shipping/receiving' = 'shipping_receiving',
  'Sorting, packing, light assembly' = 'sorting_packing_light_assembly',
  'Warehouse' = 'warehouse',
  'Freezer and refrigerated spaces (<50°F [10°C])' = 'freezer_and_refrigerated_spaces_50_f_10_c',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneOfficeSubcategory {
  'Breakrooms' = 'breakrooms',
  'Lobby' = 'lobby',
  'Occupiable storage rooms for dry materials' = 'occupiable_storage_rooms_for_dry_materials',
  'Private office space' = 'private_office_space',
  'Reception area' = 'reception_area',
  'Open office area' = 'open_office_area',
  'Coffee stations' = 'coffee_stations',
  'Conference/meeting' = 'conference_meeting',
  'Corridors' = 'corridors',
  'Security desk' = 'security_desk',
  'Computer (not printing)' = 'computer_not_printing',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneOutpatientHealthcareFacilitySubcategory {
  'Birthing room' = 'birthing_room',
  'Class 1 imaging rooms' = 'class_1_imaging_rooms',
  'Dental operatory' = 'dental_operatory',
  'General examination room' = 'general_examination_room',
  'Other dental treatment areas' = 'other_dental_treatment_areas',
  'Physical therapy exercise area' = 'physical_therapy_exercise_area',
  'Physical therapy individual room' = 'physical_therapy_individual_room',
  'Physical therapeutic pool area' = 'physical_therapeutic_pool_area',
  'Prosthetics and orthotics room' = 'prosthetics_and_orthotics_room',
  'Psychiatric consultation room' = 'psychiatric_consultation_room',
  'Psychiatric examination room' = 'psychiatric_examination_room',
  'Psychiatric group room' = 'psychiatric_group_room',
  'Psychiatric seclusion room' = 'psychiatric_seclusion_room',
  'Speech therapy room' = 'speech_therapy_room',
  'Urgent care examination room' = 'urgent_care_examination_room',
  'Urgent care observation room' = 'urgent_care_observation_room',
  'Urgent care treatment room' = 'urgent_care_treatment_room',
  'Urgent care triage room' = 'urgent_care_triage_room',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOnePublicAssemblySubcategory {
  'Auditorium seating area' = 'auditorium_seating_area',
  'Courtrooms' = 'courtrooms',
  'Libraries' = 'libraries',
  'Legislative chambers' = 'legislative_chambers',
  'Lobbies' = 'lobbies',
  'Museums (children’s)' = 'museums_children_s',
  'Museums/galleries' = 'museums_galleries',
  'Places of religious worship' = 'places_of_religious_worship',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneRetailSubcategory {
  'Sales (except as below)' = 'sales_except_as_below',
  'Barbershop' = 'barbershop',
  'Beauty and nail salons' = 'beauty_and_nail_salons',
  'Coin-operated laundries' = 'coin_operated_laundries',
  'Mall common areas' = 'mall_common_areas',
  'Pet shops (animal areas)' = 'pet_shops_animal_areas',
  'Supermarket' = 'supermarket',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneSportsAndEntertainmentSubcategory {
  'Bowling alley (seating)' = 'bowling_alley_seating',
  'Disco/dance floors' = 'disco_dance_floors',
  'Gambling casinos' = 'gambling_casinos',
  'Game arcades' = 'game_arcades',
  'Gym, sports arena (play area)' = 'gym_sports_arena_play_area',
  'Health club/aerobics room' = 'health_club_aerobics_room',
  'Health club/weight rooms' = 'health_club_weight_rooms',
  'Spectator areas' = 'spectator_areas',
  'Stages, studios' = 'stages_studios',
  'Swimming (pool and deck)' = 'swimming_pool_and_deck',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneResidentialSubcategory {
  'Common corridors' = 'common_corridors',
  'Dwelling unit (including washrooms)' = 'dwelling_unit_including_washrooms',
  'lobby/entrance' = 'lobby_entrance',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOnePharmacySubcategory {
  'Pharmacy (prep. area)' = 'pharmacy_prep._area',
  'Photo studios' = 'photo_studios',
  'Public Washroom' = 'public_washroom',
  'Private Washroom' = 'private_washroom',
  'Elevator' = 'elevator',
}

enum ParticleOneTransitSubcategory {
  'Telephone closets' = 'telephone_closets',
  'Transportation waiting' = 'transportation_waiting',
  'Elevator' = 'elevator',
}

function subcategory(category: ParticleOneSpaceCategory) {
  switch (category) {
  case ParticleOneSpaceCategory['Correctional Facilities']: return ParticleOneCorrectionalSpaceSubcategory;
  case ParticleOneSpaceCategory['Educational Facilities']: return ParticleOneEducationalSpaceSubcategory;
  case ParticleOneSpaceCategory['Food and Beverage Service']: return ParticleOneFoodAndBeverageSpaceSubcategory;
  case ParticleOneSpaceCategory['Hotels/Motels/Resorts/Dormitories']: return ParticleOneHotelSpaceSubcategory;
  case ParticleOneSpaceCategory['Manufacturing/Industrial']: return ParticleOneManufacturingSubcategory;
  case ParticleOneSpaceCategory.Office: return ParticleOneOfficeSubcategory;
  case ParticleOneSpaceCategory['Outpatient Healthcare Facilities']: return ParticleOneOutpatientHealthcareFacilitySubcategory;
  case ParticleOneSpaceCategory.Pharmacy: return ParticleOnePharmacySubcategory;
  case ParticleOneSpaceCategory['Public Assembly Spaces']: return ParticleOnePublicAssemblySubcategory;
  case ParticleOneSpaceCategory.Residential: return ParticleOneResidentialSubcategory;
  case ParticleOneSpaceCategory.Retail: return ParticleOneRetailSubcategory;
  case ParticleOneSpaceCategory['Sports and Entertainment']: return ParticleOneSportsAndEntertainmentSubcategory;
  case ParticleOneSpaceCategory.Transit: return ParticleOneTransitSubcategory;
  case ParticleOneSpaceCategory.Bank: return ParticleOneBankSubcategory;
  default: return ParticleOneOfficeSubcategory;
  }
}

enum ParticleOneSpaceType {
  Office = 'office_buildings',
  School = 'educational_facilities',
  Health = 'outpatient_heathcare_facilities',
  Retail = 'retail',
  Restaurant = 'food_and_beverage_service',
  Warehouse = 'warehouse',
  Assembly = 'public_assembly_spaces'
}

enum ParticleOneUnit {
  Imperial = 'imperial',
  'International System of Units (SI)' = 'si'
}

enum ParticleOneOccupancySchedule {
  Office = 'office',
  Restaurant = 'restaurant',
  School = 'school',
  Healthcare = 'health',
  Retail = 'retail',
  Warehouse = 'warehouse',
  Assembly = 'assembly',
  'Hotel/Residential' = 'hotel_residential',
  '24-hour Facility' = '24_hour_facility',
  'Manufacturing/Industrial' = 'manufacturing_industrial',
  '1-hour Event' = '1_hour_event',
  '2-hour Event' = '2_hour_event',
  '3-hour Event' = '3_hour_event',
  '4-hour Event' = '4_hour_event',
  '5-hour Event' = '5_hour_event',
  '6-hour Event' = '6_hour_event'
}

enum ParticleOneOccupantProfile {
  Baby = 'baby',
  Child = 'child',
  Teen = 'teen',
  Adult = 'adult',
  Senior = 'senior'
}

enum ParticleOneActivity {
  'Sitting or Resting –  Breathing' = 'resting_oral_breathing',
  'Sitting or Resting – Speaking' = 'resting_speaking',
  'Sitting or Resting – Speaking loudly' = 'resting_loudly_speaking',
  'Standing – Breathing' = 'standing_oral_breathing',
  'Standing – Speaking' = 'standing_speaking',
  'Standing – Speaking loudly' = 'standing_loudly_speaking',
  'Light exercise – Breathing' = 'light_exercise_oral_breathing',
  'Light exercise – Speaking' = 'light_exercise_speaking',
  'Light exercise – Speaking loudly' = 'light_exercise_loudly_speaking',
  'Heavy exercise – Breathing' = 'heavy_exercise_oral_breathing',
  'Heavy exercise – Speaking' = 'heavy_exercise_speaking',
  'Heavy exercise – Speaking loudly' = 'heavy_exercise_loudly_speaking'
}

enum ParticleOneMaskType {
  'None' = 'none',
  'Cloth' = 'cloth',
  'Surgical' = 'surgical',
  'N95 (no vent)' = 'N95 (no vent)',
}

enum ParticleOneTestingProgram {
  'One-time' = 'pass_through',
  'Bubble or Regular' = 'daily'
}

enum ParticleOneTestingTechnology {
  Antigen = 'antigen',
  PCR = 'pcr',
}

enum ParticleOneTestingCadence {
  'One time' = 'once',
  'Every Day' = 'daily',
  'Every 2nd Day' = 'every_2_days',
  'Every 3rd Day' = 'every_3_days',
  'Every 4th Day' = 'every_4_days'
}

enum ParticleOneMechanicalSystemType {
  'Mixing High' = 'mixing_high',
  'Mixing Low' = 'mixing_low',
  'Displacement Ventilation (Low to High)' = 'displacement_low',
  'Underfloor Ventilation' = 'underfloor'
}

enum ParticleOneMechanicalFilterType {
  'None' = 'none',
  'MERV 6' = 'merv6',
  'MERV 7' = 'merv7',
  'MERV 8' = 'merv8',
  'MERV  9' = 'merv9',
  'MERV 10' = 'merv10',
  'MERV 11' = 'merv11',
  'MERV 12' = 'merv12',
  'MERV 13' = 'merv13',
  'MERV  14' = 'merv14',
  'MERV 15' = 'merv15',
  'MERV 16' = 'merv16',
  'HEPA' = 'hepa'
}

export {
  ParticleOneCreateSpaceRequest,
  ParticleOneSpaceCategory,
  ParticleOneSpaceType,
  ParticleOneUnit,
  ParticleOneOccupancySchedule,
  ParticleOneOccupantProfile,
  ParticleOneActivity,
  ParticleOneMaskType,
  ParticleOneTestingProgram,
  ParticleOneTestingTechnology,
  ParticleOneTestingCadence,
  ParticleOneMechanicalSystemType,
  ParticleOneMechanicalFilterType,
  ParticleOneSubcategory,
  subcategory
};

function createRouteObject(route) {
  // Gather any named components and import them
  const namedComponents = Object.fromEntries(
    Object.entries(route.components ?? {})
      .map(([name, componentPath]) => [
        name,
        () => import(/* webpackChunkName: "[request]" */ '@/components/' + componentPath + '.vue') //eslint-disable-line prefer-template
      ])
  );
  const routeObject = {
    ...route,
    components: {
      default: () => import(/* webpackChunkName: "[request]" */ '@/components/' + route.componentPath + '.vue'), //eslint-disable-line prefer-template
      ...namedComponents,
    }
  };
  if (!route.children) {
    return routeObject;
  }
  return { ...routeObject, children: route.children.map(childRoute => createRouteObject(childRoute)) };
}

function redirectToDashboardBuildingSummary(to) {
  if (to?.params.tenantId == null) {
    return { name: 'TenantList' };
  }
  return { name: 'DashboardBuildingSummary' };
}

const dashboardSpaceCommonComponents = {
  DashboardSpaceOverview: 'dashboard/DashboardSpaceOverview',
  DashboardSpaceSimulations: 'dashboard/DashboardSpaceSimulations',
  DashboardSpaceDetails: 'dashboard/DashboardSpaceDetails',
};

const routes = [
  { name: 'AppContainer', path: '', componentPath: 'AppContainer', redirect: { name: 'ContentContainer' }, children: [
    { name: 'ContentContainer', path: '', componentPath: 'ContentContainer', redirect: { name: 'DashboardContainer' }, children: [
      { name: 'DashboardContainer', path: '', componentPath: 'dashboard/DashboardContainer', redirect: redirectToDashboardBuildingSummary, children: [
        { name: 'DashboardBuildingSummary', path: '/:tenantId/dashboard/', componentPath: 'dashboard/DashboardBuildingSummary' },
        { name: 'DashboardSpaceSummary', path: '/:tenantId/dashboard/:buildingId/spaces/', componentPath: 'dashboard/DashboardSpaceSummary' },
        { name: 'DashboardSpaceDetailContainer', path: '/:tenantId/dashboard/:buildingId/spaces/:spaceId/', componentPath: 'dashboard/DashboardSpaceDetailContainer', children: [
          { name: 'DashboardSpaceOverview', path: '/:tenantId/dashboard/:buildingId/spaces/:spaceId/overview/', componentPath: 'dashboard/DashboardSpaceOverview', components: dashboardSpaceCommonComponents },
          { name: 'DashboardSpaceSimulations', path: '/:tenantId/dashboard/:buildingId/spaces/:spaceId/simulations/', componentPath: 'dashboard/DashboardSpaceSimulations', components: dashboardSpaceCommonComponents },
          { name: 'DashboardSpaceDetails', path: '/:tenantId/dashboard/:buildingId/spaces/:spaceId/details/', componentPath: 'dashboard/DashboardSpaceDetails', components: dashboardSpaceCommonComponents },
          { name: 'DashboardSpaceProductsAndSolutions', path: '/:tenantId/dashboard/:buildingId/spaces/:spaceId/products/', componentPath: 'dashboard/DashboardSpaceProductsAndSolutions' },
        ]}
      ]},
      { name: 'Users', path: '/users/', componentPath: 'users/Users' },
      { name: 'Settings', path: '/settings/', componentPath: 'settings/Settings' },
      { name: 'Help', path: '/help/', componentPath: 'help/Help' }
    ]}
  ]},
  { path: '/login/', componentPath: 'login/Login', name: 'Login', meta: { requiresAuth: false } },
  { path: '/login-complete/', componentPath: 'login/PostLogin', name: 'PostLogin', meta: { requiresAuth: false } },
  { path: '/terms/', componentPath: 'terms/LicenseAgreement', name: 'LicenseAgreement' },
  { path: '/tenants/', componentPath: 'login/TenantList', name: 'TenantList' },
  { path: '/', redirect: { name: 'AppContainer' }},
  { path: '/:pathMatch(.*)*', componentPath: 'error/PageNotFound'},
].map(route => createRouteObject(route));

export default routes;

import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ParticleOneTenantResponse } from '../models/ParticleOneTenantResponse';
import { ParticleOneLicenseAgreement } from '../models/ParticleOneLicenseAgreement';

export default {
  async getAllTenants() {
    const response: AxiosResponse<ParticleOneTenantResponse> = await axios.get('/tenants/');
    return response.data;
  },
  async getLicenseAgreement() {
    const response: AxiosResponse<ParticleOneLicenseAgreement> = await axios.get('/terms/agreement');
    return response;
  },
  async acceptLicenseAgreement(eula_id: number) {
    const response: AxiosResponse<ParticleOneLicenseAgreement> = await axios.post('/terms/agreement', {
      eula_id
    });
    return response;
  }
};
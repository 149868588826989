import { ParticleOneTenant } from '@/network/models/ParticleOneTenant';

class Tenant {
  tenant: ParticleOneTenant

  get slug(): string {
    return this.tenant.slug;
  }

  get name(): string {
    return this.tenant.name;
  }

  constructor(tenant: ParticleOneTenant) {
    this.tenant = tenant;
  }
}

export {
  Tenant
};
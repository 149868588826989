import { ActionTree, GetterTree, MutationTree, Module} from 'vuex';
import { ParticleOneLicenseAgreement } from '@/network/models/ParticleOneLicenseAgreement';
import authorizationApiService from '@/network/services/authorization';

interface LicenseState {
  licenseData: ParticleOneLicenseAgreement,
}

const state: LicenseState = {
  licenseData: { user_is_current: true },
};

const mutations: MutationTree<LicenseState> = {
  setLicenseData(state: LicenseState, licenseData: ParticleOneLicenseAgreement) {
    state.licenseData = licenseData;
  },
};

const actions: ActionTree<LicenseState, any> = {
  async getLicenseData({ commit }) {
    const { data } = await authorizationApiService.getLicenseAgreement();
    commit('setLicenseData', data);
  },
  async acceptLicenseAgreement({ commit }, license_id: number) {
    const { data } = await authorizationApiService.acceptLicenseAgreement(license_id);
    commit('setLicenseData', data);
  }
};

const getters: GetterTree<LicenseState, any> = {
  licenseData: state => state.licenseData,
};

const licenseAgreementModule: Module<LicenseState,any> = {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};

export default licenseAgreementModule;
import store from '@/store';

function nameAndPathAreEqual(to, from) {
  return to.name === from.name && to.path === from.path;
}

export function beforeEach(to, from, next) {
  const authenticated = store.getters.loggedIn || process.env.VUE_APP_ENVIRONMENT === 'local';
  const requiresAuth = to.matched.some(record => record.meta?.requiresAuth == undefined ? true : record.meta.requiresAuth);

  if (requiresAuth && !authenticated) next({ name: 'Login', query: { redirect_from: window.location.hash.slice(1) }});
  else if (to.name === 'Login' && authenticated) next(to.query.redirect_from || { name: 'AppContainer' });
  else if (nameAndPathAreEqual(to, from)) return;
  else next();
}
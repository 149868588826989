import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ParticleOneUserPermissionRequest } from '../models/ParticleOneUserPermissionRequest';
import { ParticleOneTenantResponse } from '../models/ParticleOneTenantResponse';

export default {
  async getAllUserPermissions(params: any) {
    const response: AxiosResponse<ParticleOneTenantResponse> = await axios.get('/permissions/', { params });
    return response.data;
  },
  async addUserPermission(request: ParticleOneUserPermissionRequest) {
    const response: AxiosResponse<string> = await axios.post('/user/permissions/', request);
    return response.data;
  },
  async removeUserPermission(request: ParticleOneUserPermissionRequest) {
    const response: AxiosResponse<string> = await axios.delete('/user/permissions/', { data: request });
    return response.data;
  }
};
import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { beforeEach } from './before-routing';

const router = createRouter({
  routes,
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
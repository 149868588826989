import { createApp } from 'vue';

// @ts-ignore
import App from './App.vue';

import axios from 'axios';
import { setupAxiosDefaultsAndInterceptors } from './network/axios-setup';
import VueApexCharts from 'vue3-apexcharts';
// @ts-ignore should add the types for this library
import { createDynamicForms } from '@asigloo/vue-dynamic-forms';


// @ts-ignore
import router from './router';
import store from './store';

// @ts-ignore
import titleMixin from './mixins/title';

// This modifies the global axios state for future imports
// Any future imports of the axios library will be using the
// special configuration (base URL, authentication, access token)
setupAxiosDefaultsAndInterceptors(axios);

const VueDynamicForms = createDynamicForms();

// @ts-ignore
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueApexCharts);
app.use(VueDynamicForms);
app.mixin(titleMixin);
app.mount('#mounting_node');

// Add `window` object to global properties
// See: https://github.com/vuejs/vue-next-webpack-preview/issues/15
app.config.globalProperties.window = window;

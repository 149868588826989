import { ParticleOneCreateBuildingRequest } from '@/network/models/ParticleOneCreateBuildingRequest';
import { ParticleOneCreateSpaceRequest, ParticleOneTestingProgram, ParticleOneTestingCadence } from '@/network/models/ParticleOneCreateSpaceRequest';
import { ParticleOneUpdateBuildingRequest } from '@/network/models/ParticleOneUpdateBuildingRequest';
import { createFormData, FORM_FIELD_PAGES } from './create-space';

function createBuildingRequest(formData: any): ParticleOneCreateBuildingRequest {
  return {
    name: isNullUndefinedOrEmpty(formData['name']) ? undefined : formData['name'],
    region: formData['region'],
    region_code: formData['region_code'],
    city: formData['city'],
    country: formData['country'],
    street_address: formData['street_address'],
    latitude: formData['latitude'],
    longitude: formData['longitude']
  };
}

function createUpdateBuildingRequest(formData: any, building_id: number): ParticleOneUpdateBuildingRequest {
  return {
    id: building_id,
    name: isNullUndefinedOrEmpty(formData['name']) ? undefined : formData['name'],
    region: formData['region'],
    region_code: formData['region_code'],
    city: formData['city'],
    country: formData['country'],
    street_address: formData['street_address'],
    latitude: formData['latitude'],
    longitude: formData['longitude']
  };
}

function createSpaceRequest(formData: any): ParticleOneCreateSpaceRequest {
  return {
    id: formData['id'],
    name: formData['name'],
    description: formData['description'],
    building_id: formData['buildingId'],
    category: formData['spaceType'],
    sub_category: formData['subcategory'],
    units: formData['units'],
    floor_area: convertToNumber(formData['floorArea']),
    ceiling_height: convertToNumber(formData['ceilingHeight']),
    peak_occupancy: convertToNumber(formData['peakOccupancy']),
    occupant_duration_minutes: formData['occupantDurationMinutes'],
    occupant_schedule: formData['occupantSchedule'],
    occupant_profile: formData['occupantProfile'],
    activity: formData['activity'],
    mask_type: formData['maskType'],
    mask_use_rate: convertToPercent(formData['maskUse']),
    social_distancing: formData['socialDistancing'],
    vaccine_mandate: formData['vaccineMandate'],
    vaccine_mandate_booster: formData['vaccineMandateBooster'],
    vaccine_mandate_flu: formData['vaccineMandateFlu'],
    background_vaccination_rate: backgroundVaccinationRateConversion(formData),
    background_vaccination_rate_booster: backgroundVaccinationRateBoosterConversion(formData),
    background_vaccination_rate_flu: backgroundVaccinationRateFluConversion(formData),
    testing_program: formData['testingProgram'],
    enhanced_cleaning: formData['enhancedCleaning'],
    testing_technology: validateTestingFields(formData, 'testingTechnology'),
    program: validateTestingFields(formData, 'testingProgramType'),
    testing_cadence: testingCadenceConversion(formData),
    testing_unvaccinated_only: validateTestingFields(formData, 'testingUnvaccinated'),
    supply_airflow_rate: supplyAirflowConversion(formData),
    outside_air_rate: outsideAirRateConversion(formData),
    mechanical_system: formData['mechanicalSystem'],
    filtration: formData['filtration'],
    temperature: convertToNumber(formData['temperature']),
    relative_humidity_rate: convertToPercent(formData['relativeHumidityRate']),
    barriers: formData['barriers'],
    air_cleaning_devices: formData['airCleaningDevices'],
    total_clean_air_delivery_rate: convertToNumber(formData['totalCleanAirDeliveryRate']),
    upper_room_uvgi: formData['upperRoomUvgi']
  };
}

function createLabelList(options: { [key: string]: string }) {
  return Object
    .values(options)
    .map(option => { return { value: option, label: option }; });
}

function missingFields(formData: any): boolean {
  return Object
    .entries(createBuildingRequest(formData))
    .filter(([key,]) => !['name', 'latitude', 'longitude'].includes(key))
    .some(([,value]) => isNullUndefinedOrEmpty(value));
}

function missingSpaceFields(formData: any, page: number): boolean {
  return !FORM_FIELD_PAGES[page]
    .filter(field => field.required(formData))
    .every(field => field.isValid(formData));
}

function missingAnySpaceFields(response: ParticleOneCreateSpaceRequest): boolean {
  const formData = createFormData(response);
  return !FORM_FIELD_PAGES
    .flat()
    .filter(field => field.required(formData))
    .every(field => field.isValid(formData));
}

function isNullUndefinedOrEmpty(value: string | undefined | null): boolean {
  if (typeof value === 'boolean') {
    return false;
  }
  return !value || /^\s*$/.test(value);
}

function convertToNumber(string: string | undefined | null) {
  if (typeof string === 'undefined' || string === null) {
    return string;
  }
  if (string?.length === 0) {
    return null;
  }
  return Number(string) > 0 ? Number(string) : undefined;
}

function convertToPercent(string: string | undefined | null) {
  if (typeof string === 'undefined' || string === null) {
    return string;
  }
  if (string?.length === 0) {
    return null;
  }
  return Number(string) > 0 && Number(string) <= 100 ? Number(string) : undefined;
}

function supplyAirflowConversion(formData: any) {
  return convertToNumber(formData['supplyAirflowRate']);
}

function outsideAirRateConversion(formData: any) {
  const outsideAirRate = convertToNumber(formData['outsideAirRate']);
  const supplyAirflowRate = convertToNumber(formData['supplyAirflowRate']);
  if (formData['oaUnit'] === 'unit') {
    return !outsideAirRate ? undefined : outsideAirRate;
  }
  return !outsideAirRate || !supplyAirflowRate ? undefined : supplyAirflowRate * (outsideAirRate / 100);
}

function backgroundVaccinationRateConversion(formData: any) {
  // if vaccine_mandate === true -> set background_vaccination_rate = 100
  // if backgroundVaccinationRate === default -> background_vaccination_rate = null
  if (isNullUndefinedOrEmpty(formData['backgroundVaccinationRate'])) {
    return undefined;
  }
  if (formData['vaccineMandate']) {
    return 100;
  }
  if (formData['backgroundVaccinationRate'] === 'default') {
    return null;
  }
  return convertToPercent(formData['vaccinationPercent']);
}

function backgroundVaccinationRateBoosterConversion(formData: any) {
  // if vaccine_mandate_booster === true -> set background_vaccination_rate_booster = 100
  // if backgroundVaccinationRateBooster === default -> background_vaccination_rate_booster = null
  if (isNullUndefinedOrEmpty(formData['backgroundVaccinationRateBooster'])) {
    return undefined;
  }
  if (formData['vaccineMandateBooster']) {
    return 100;
  }
  if (formData['backgroundVaccinationRateBooster'] === 'default') {
    return null;
  }
  return convertToPercent(formData['vaccinationPercentBooster']);
}

function backgroundVaccinationRateFluConversion(formData: any) {
  // if vaccine_mandate_flu === true -> set background_vaccination_rate_flu = 100
  // if backgroundVaccinationRateFlu === default -> background_vaccination_rate_flu = null
  if (isNullUndefinedOrEmpty(formData['backgroundVaccinationRateFlu'])) {
    return undefined;
  }
  if (formData['vaccineMandateFlu']) {
    return 100;
  }
  if (formData['backgroundVaccinationRateFlu'] === 'default') {
    return null;
  }
  return convertToPercent(formData['vaccinationPercentFlu']);
}

function validateTestingFields(formData: any, field: string) {
  if (isNullUndefinedOrEmpty(formData['testingProgram'])) {
    return undefined;
  }
  return formData['testingProgram'] ? formData[field] : null;
}

function testingCadenceConversion(formData: any) {
  // if testingProgram === 'One-time' -> set testingCadence = 'once'
  if (formData['testingProgramType'] === ParticleOneTestingProgram['One-time']) {
    formData['testingCadence'] = ParticleOneTestingCadence['One time'];
  }
  return validateTestingFields(formData, 'testingCadence');
}

function getPage(response: ParticleOneCreateSpaceRequest) {
  const formData = createFormData(response);
  const page = FORM_FIELD_PAGES.findIndex(fields => fields.every(field => field.required(formData) && !isNullUndefinedOrEmpty(formData[field.name])));
  if (page < 0) {
    return 0;
  }
  return page === 4 && formData['id'] == null ? 0 : page + 1;
}

export {
  missingFields,
  createBuildingRequest,
  createLabelList,
  createSpaceRequest,
  missingSpaceFields,
  getPage,
  missingAnySpaceFields,
  isNullUndefinedOrEmpty,
  createUpdateBuildingRequest
};
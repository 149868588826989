// https://github.com/Flyrell/axios-auth-refresh#readme
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { AXIOS_AUTH_REFRESH_OPTIONS } from './axios-constants';
import { refreshAuthLogic, addAccessTokenToRequest, handleTimeoutError, addTenantIdToRequest, handleUnauthorizedResponse, handleUnauthenticatedError, handleServerError, refreshAccessTokenBeforeExpiry } from './axios-configuration';
import { AxiosInstance } from 'axios';

export function setupAxiosDefaultsAndInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

  // See https://github.com/Flyrell/axios-auth-refresh#available-options
  createAuthRefreshInterceptor(
    axiosInstance,
    refreshAuthLogic,
    AXIOS_AUTH_REFRESH_OPTIONS
  );

  // Automatically check if token refresh is required at set interval
  const fifteenMinuteIntervalMilliseconds = 15 * 60 * 1000;
  setInterval(refreshAccessTokenBeforeExpiry, fifteenMinuteIntervalMilliseconds);

  axiosInstance.interceptors.request.use((request) => addAccessTokenToRequest(request));
  axiosInstance.interceptors.request.use((request) => addTenantIdToRequest(request));
  axiosInstance.interceptors.response.use(response => response, async error => handleTimeoutError(error));
  axiosInstance.interceptors.response.use(response => response, async error => handleUnauthorizedResponse(error));
  axiosInstance.interceptors.response.use(response => response, async error => handleUnauthenticatedError(error));
  axiosInstance.interceptors.response.use(response => response, async error => handleServerError(error));

  return axiosInstance;
}
import { ParticleOneBuilding } from '@/network/models/ParticleOneBuilding';
import { ParticleOneConfiguration } from '@/network/models/ParticleOneConfiguration';
import { ParticleOneSpace } from '@/network/models/ParticleOneSpace';
import { ParticleOneScaledResults, ParticleOneScaledResultsMetadata } from '@/network/models/ParticleOneScaledResults';
import { DASHBOARD_LABEL_CONSTANTS } from './dashboard-setting-constants';
import { getRegionCodeFromValue } from './create-building-constants';
import { ParticleOneBackgroundInfectionInformation } from '@/network/models/ParticleOneBackgroundInfection';

class DashboardSpace {
  space: ParticleOneSpace

  get key(): string {
    return `${this.space.name}-${this.space.id}`;
  }

  get name(): string {
    return this.space.name;
  }

  get description(): string | null | undefined {
    return this.space.description;
  }

  get lastUpdated(): string {
    return new Date(this.space.updated_at).toLocaleDateString();
  }

  get id(): number {
    return this.space.id;
  }

  get risk(): DashboardRisk {
    return new DashboardRisk(this.space.risk_score);
  }

  constructor(space: ParticleOneSpace) {
    this.space = space;
  }
}

class DashboardBuilding {
  building: ParticleOneBuilding

  get key(): string {
    return `${this.building.name}-${this.building.city}`;
  }

  get name(): string {
    return this.building.name;
  }

  get city(): string {
    return this.building.city;
  }

  get region(): string {
    return this.building.region;
  }

  get group(): string {
    return '- -';
  }

  get lastUpdated(): string {
    return new Date(this.building.updated_at).toLocaleDateString();
  }

  get id(): number {
    return this.building.id;
  }

  get address(): string {
    return [
      this.building.street_address,
      this.city,
      [getRegionCodeFromValue(this.region), this.building.region_code].filter(string => string).join(' ')
    ].filter(string => string).join(', ');
  }

  get mapAddress(): string {
    return [
      `${this.city},`,
      getRegionCodeFromValue(this.region),
      this.building.region_code
    ].filter(string => string).join(' ');
  }

  get shortAddress(): string {
    return this.building.street_address;
  }

  get risk(): DashboardRisk {
    return new DashboardRisk(this.building.max_risk);
  }

  get isNewlyCreated(): boolean {
    return (new Date().getTime() - new Date(this.building.created_at).getTime()) <= 30000;
  }

  get coordinate(): number[] | null {
    if (this.building.longitude == null || this.building.latitude == null) {
      return null;
    }
    return [this.building.longitude, this.building.latitude];
  }

  get spaceRiskRatingCount(): { name: string, count: number,  }[] {
    const riskRatings = this.building.risk_ratings?.map(rating => new DashboardRisk(rating)) ?? [];
    return Object
      .values(RiskState)
      .map(state => ({ name: state, count: riskRatings.filter(rating => rating.state === state).length }));
  }

  constructor(building: ParticleOneBuilding) {
    this.building = building;
  }
}

class DashboardSetting {
  key: string
  property: string

  get shortLabel(): string {
    const mapping = DASHBOARD_LABEL_CONSTANTS[this.key];
    if (mapping == null) {
      return this.key.replace(/_/g, ' ');
    }
    return mapping.shortLabel;
  }

  get fullLabel(): string {
    const mapping = DASHBOARD_LABEL_CONSTANTS[this.key];
    if (mapping == null) {
      return this.key.replace(/_/g, ' ');
    }
    return mapping.fullLabel;
  }

  get propertyLabel(): string {
    const mapping = DASHBOARD_LABEL_CONSTANTS[this.key];
    if (mapping == null) {
      return this.property;
    }
    return mapping.valueMapper(this.property);
  }

  constructor(key: string, property: string) {
    this.key = key;
    this.property = property;
  }
}

class DashboardConfiguration {
  configuration: ParticleOneConfiguration
  scaledResults: ParticleOneScaledResults | null

  get settings(): DashboardSetting[] {
    return Object
      .entries(this.configuration)
      .filter(([key, property]) => key !== 'id' && property != null)
      .map(([key, property]) => new DashboardSetting(key, property));
  }

  get key(): string {
    return Object.values(this.configuration).join('-');
  }

  get scaledResultsForCurrentConfigurationId(): number[] {
    const results = this.scaledResults ?? {};
    if (results == null) {
      return [];
    }
    return results[this.configuration.id];
  }

  get risk(): DashboardRisk {
    const length = this.scaledResultsForCurrentConfigurationId?.length;
    if (length == null || length == 0) {
      return new DashboardRisk(undefined);
    }
    return new DashboardRisk(this.scaledResultsForCurrentConfigurationId[length - 1]);
  }

  get id(): number {
    return this.configuration.id;
  }

  constructor(configuration: ParticleOneConfiguration, scaledResults: ParticleOneScaledResults | null) {
    this.configuration = configuration;
    this.scaledResults = scaledResults;
  }

  isEqual(setting: DashboardSetting) {
    return this.settings.find(currentSetting => currentSetting.key === setting.key)?.property === setting.property;
  }

  getPropertyValueForKey(key: string) {
    return Object(this.configuration)[key];
  }
}

// IMPORTANT: ordering of enum values below indicates relative risk (lowest to highest)
enum RiskState {
  Prepared = 'Prepared',
  Pending = 'Pending',
  Caution = 'Caution',
}

class DashboardRisk {
  riskValue?: number;

  get state(): RiskState {
    if (this.riskValue == null) {
      return RiskState.Pending;
    }
    return this.riskValue < 1 ? RiskState.Prepared : RiskState.Caution;
  }

  get title() {
    return this.state.toString();
  }

  get color() {
    switch (this.state) {
    case RiskState.Pending: return 'pending-text';
    case RiskState.Prepared: return 'prepared-text';
    case RiskState.Caution: return 'caution-text';
    default: 'pending-text';
    }
  }

  get backgroundColor() {
    switch (this.state) {
    case RiskState.Pending: return 'pending-bg';
    case RiskState.Prepared: return 'prepared-bg';
    case RiskState.Caution: return 'caution-bg';
    default: 'pending-bg';
    }
  }

  get borderColor() {
    switch (this.state) {
    case RiskState.Pending: return 'pending-border';
    case RiskState.Prepared: return 'prepared-border';
    case RiskState.Caution: return 'caution-border';
    default: 'pending-border';
    }
  }

  get markerColor() {
    switch (this.state) {
    case RiskState.Pending: return 'bg-gray-400';
    case RiskState.Prepared: return 'bg-teal-500';
    case RiskState.Caution: return 'bg-orange-400';
    default: 'bg-gray-400';
    }
  }

  constructor(riskValue?: number) {
    this.riskValue = riskValue;
  }
}

type DashboardPoint = { x: number, y: number | null };

class DashboardScaledResults {
  scaled_data: ParticleOneScaledResults
  metadata: ParticleOneScaledResultsMetadata

  constructor(scaled_data: ParticleOneScaledResults, metadata: ParticleOneScaledResultsMetadata) {
    this.scaled_data = scaled_data;
    this.metadata = metadata;
  }

  get dates(): Date[] {
    return this.metadata.dates.map((date: string) => new Date(date));
  }

  get after_days(): number {
    return this.metadata.after_days;
  }

  configurationResults(id: number): DashboardPoint[] {
    return this.dates.map((date, index) => ({x: date.getTime(), y: this.scaled_data[id] ? this.scaled_data[id][index] : null}));
  }
}

enum DashboardRiskBadgeStyleType {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

type DashboardFormattedInfectionInformation = { percent: string, date: string }

class DashboardBackgroundInfectionInformation {
  background_infection: ParticleOneBackgroundInfectionInformation

  get formattedInformation(): DashboardFormattedInfectionInformation[] {
    return this.background_infection.rates
      .map((rate, index) => ({
        percent: `${(rate * 100).toFixed(2)}%`,
        date: new Date(this.background_infection.dates[index]).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
      }));
  }

  constructor(background_infection: ParticleOneBackgroundInfectionInformation) {
    this.background_infection = background_infection;
  }
}

export {
  DashboardSpace,
  DashboardBuilding,
  DashboardConfiguration,
  DashboardScaledResults,
  DashboardRisk,
  DashboardRiskBadgeStyleType,
  DashboardBackgroundInfectionInformation,
  RiskState,
};

import { createStore } from 'vuex';

import dashboard from './modules/dashboard';
import authentication from './modules/authentication';
import user from './modules/users';
import licenseAgreement from './modules/license-agreement';

export default createStore({
  modules: {
    dashboard,
    authentication,
    user,
    licenseAgreement
  }
});
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { ParticleOneBuildingResponse } from '../models/ParticleOneBuildingResponse';
import { ParticleOneConfigurationResponse } from '../models/ParticleOneConfigurationResponse';
import { ParticleOneCurrentConfigurationResponse } from '../models/ParticleOneCurrentConfigurationResponse';
import { ParticleOneSpaceResponse } from '../models/ParticleOneSpaceResponse';
import { ParticleOneScaledResultsResponse, ParticleOneScaledDateRange } from '../models/ParticleOneScaledResultsResponse';
import { ParticleOneBuildingDetailResponse } from '../models/ParticleOneBuildingDetailResponse';
import { ParticleOneSpaceDetailResponse } from '../models/ParticleOneSpaceDetailResponse';
import { ParticleOneCreateBuildingRequest } from '../models/ParticleOneCreateBuildingRequest';
import { ParticleOneCreateSpaceRequest } from '../models/ParticleOneCreateSpaceRequest';
import { ParticleOneCreateSpaceResponse } from '../models/ParticleOneCreateSpaceResponse';
import { ParticleOneUpdateCurrentConfigurationRequest } from '../models/ParticleOneUpdateCurrentConfigurationRequest';
import { ParticleOneUpdateCurrentConfigurationResponse } from '../models/ParticleOneUpdateCurrentConfigurationResponse';
import { ParticleOneParallelCoordinateResponse } from '../models/ParticleOneParallelCoordinatePlotResponse';
import { ParticleOneUpdateSelectedConfigurationRequest } from '../models/ParticleOneUpdateSelectedConfigurationRequest';
import { ParticleOneUpdateBuildingRequest } from '../models/ParticleOneUpdateBuildingRequest';
import { Ref } from 'vue';
import { default as useSWRVdefault } from 'swrv';
import { IKey } from 'swrv/dist/types';
import { ParticleOneBackgroundPathogenData, Pathogen } from '../models/ParticleOneBackgroundPathogenData';

// Override `useSWRV` function with configuration defaults
// See https://docs-swrv.netlify.app/configuration.html
const useSWRV = (key: IKey, fetcher: any, config = {}) => {
  return useSWRVdefault(key, fetcher, {
    shouldRetryOnError: false,
    revalidateDebounce: 5000,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
    ...config,
  });
};

export default {
  getAllBuildings() {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneBuildingResponse>) => response.data.buildings);
    return useSWRV('/buildings/', fetcher);
  },
  getAllSpaces(buildingId: number) {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneSpaceResponse>) => response.data.spaces);
    return useSWRV(`/buildings/${buildingId}/spaces/`, fetcher);
  },
  getConfigurations(spaceId: number) {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneConfigurationResponse>) => response.data);
    return useSWRV(`/spaces/${spaceId}/selected-configurations/`, fetcher);
  },
  getCurrentConfiguration(spaceId: number) {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneCurrentConfigurationResponse>) => response.data);
    return useSWRV(`/spaces/${spaceId}/current-configuration/`, fetcher);
  },
  getScaledResults(spaceId: number, params: ParticleOneScaledDateRange) {
    const fetcher = (url: string) => axios
      .get(url, { params })
      .then((response: AxiosResponse<ParticleOneScaledResultsResponse>) => response.data);
    return useSWRV(`/spaces/${spaceId}/scaled-data/`, fetcher);
  },
  getBuildingDetail(buildingId: number) {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneBuildingDetailResponse>) => response.data);
    return useSWRV(`/buildings/${buildingId}/`, fetcher);
  },
  getSpaceDetail(buildingId: number, spaceId: number) {
    const fetcher = (url: string) => axios
      .get(url)
      .then((response: AxiosResponse<ParticleOneSpaceDetailResponse>) => response.data);
    return useSWRV(`/buildings/${buildingId}/spaces/${spaceId}/`, fetcher);
  },
  /* Date should be ISO-8601 syntax */
  getPathogenData(pathogens: Pathogen[], location_id: Ref<any>, date?: string) {
    const params = {
      date: date,
      pathogen: pathogens.join(',')
    };
    const fetcher = (url: string) => axios
      .get(url, { params })
      .then((response: AxiosResponse<ParticleOneBackgroundPathogenData>) => response.data);
    return useSWRV(() => location_id.value && `/pathogen/curves?location_id=${location_id.value}`, fetcher, { revalidateOnFocus: false});
  },
  async createBuilding(request: ParticleOneCreateBuildingRequest) {
    const response: AxiosResponse<ParticleOneBuildingResponse> = await axios.post('/buildings/', request);
    return response.data;
  },
  async createSpace(request: ParticleOneCreateSpaceRequest) {
    const response: AxiosResponse<ParticleOneCreateSpaceResponse> = await axios.post(`/buildings/${request.building_id}/spaces/`, request);
    return response.data;
  },
  async cloneSpace(request: ParticleOneCreateSpaceRequest, sourceId: number) {
    const response: AxiosResponse<ParticleOneCreateSpaceResponse> = await axios.post(`/buildings/${request.building_id}/spaces/?source=${sourceId}`, request);
    return response.data;
  },
  async updateSpace(request: ParticleOneCreateSpaceRequest) {
    const response: AxiosResponse<ParticleOneCreateSpaceResponse> = await axios.patch(`/buildings/${request.building_id}/spaces/${request.id}/`, request);
    return response.data;
  },
  async moveSpace(buildingId: number, spaceId: number, destinationBuildingId: number) {
    const response: AxiosResponse<ParticleOneCreateSpaceResponse> = await axios.patch(`/buildings/${buildingId}/spaces/${spaceId}/`, { building_id: destinationBuildingId });
    return response.data;
  },
  async swapConfigurations(request: ParticleOneUpdateCurrentConfigurationRequest) {
    const response: AxiosResponse<ParticleOneUpdateCurrentConfigurationResponse> = await axios.put(`/spaces/${request.space_id}/current-configuration/`, request);
    return response.data;
  },
  async getParallelCoordinatePlot(spaceId: number) {
    const response: AxiosResponse<ParticleOneParallelCoordinateResponse> = await axios.get(`/spaces/${spaceId}/parallel-coordinate-plot-data/`);
    return response.data;
  },
  async updateConfiguration(request: ParticleOneUpdateSelectedConfigurationRequest) {
    const response: AxiosResponse<ParticleOneConfigurationResponse> = await axios.put(`/spaces/${request.space_id}/selected-configurations/`, request);
    return response.data;
  },
  async updateBuilding(request: ParticleOneUpdateBuildingRequest) {
    const response: AxiosResponse<ParticleOneBuildingResponse> = await axios.put(`/buildings/${request.id}/`, request);
    return response.data;
  },
  async deleteSpace(buildingId: number, spaceId: number) {
    const response: AxiosResponse<ParticleOneSpaceResponse> = await axios.delete(`/buildings/${buildingId}/spaces/${spaceId}/`);
  },
  async deleteBuilding(buildingId: number) {
    const response: AxiosResponse<ParticleOneBuildingDetailResponse> = await axios.delete(`/buildings/${buildingId}/`);
    return response.data;
  }
};
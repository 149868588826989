import { Module } from 'vuex';
import { DashboardBuilding, DashboardConfiguration, DashboardSpace, DashboardScaledResults, DashboardBackgroundInfectionInformation } from '@/models/dashboard';
import buildingApiService from '@/network/services/building';
import { ParticleOneBuilding } from '@/network/models/ParticleOneBuilding';
import { ParticleOneSpace } from '@/network/models/ParticleOneSpace';
import { ParticleOneConfiguration } from '@/network/models/ParticleOneConfiguration';
import { ParticleOneCurrentConfiguration } from '@/network/models/ParticleOneCurrentConfiguration';
import {
  ParticleOneScaledResults,
  ParticleOneScaledResultsMetadata
} from '@/network/models/ParticleOneScaledResults';
import { createBuildingRequest, createSpaceRequest, createUpdateBuildingRequest } from '@/models/create-building';
import { ParticleOneCreateSpaceRequest } from '@/network/models/ParticleOneCreateSpaceRequest';
import { ParticleOneCreateSpaceResponse } from '@/network/models/ParticleOneCreateSpaceResponse';
import { ParticleOneBackgroundInfectionInformation } from '@/network/models/ParticleOneBackgroundInfection';

interface DashboardState {
  spaceData: ParticleOneSpace[],
  configurationData: ParticleOneConfiguration[]
  selectedBuilding: ParticleOneBuilding | null
  currentConfigurationData: ParticleOneCurrentConfiguration | null
  scaledResultsData: ParticleOneScaledResults | null
  scaledResultsMetadata: ParticleOneScaledResultsMetadata | null
  selectedSpace: ParticleOneSpace | null,
  isLoading: boolean,
  isSavingSpace: boolean,
  spaceResponse: ParticleOneCreateSpaceRequest | null,
  parallelCoordinatePlot: string | null,
  backgroundInfectionData: ParticleOneBackgroundInfectionInformation | null,
}

interface ScaledResultsRequestParameters {
  spaceId: number,
  startDate?: string
  endDate?: string
}

const dashboard: Module<any, any> = {
  state: {
    spaceData: [],
    configurationData: [],
    selectedBuilding: null,
    currentConfigurationData: null,
    scaledResultsData: null,
    scaledResultsMetadata: null,
    selectedSpace: null,
    isLoading: false,
    isSavingSpace: false,
    spaceResponse: null,
    parallelCoordinatePlot: null,
    backgroundInfectionData: null,
  } as DashboardState,
  getters: {
    selectedBuilding(state: DashboardState): DashboardBuilding | null {
      if (!state.selectedBuilding) {
        return null;
      }
      return new DashboardBuilding(state.selectedBuilding);
    },
    configurations(state: DashboardState): DashboardConfiguration[] {
      return state.configurationData
        ?.filter(configuration => configuration.id !== state.currentConfigurationData?.configuration_policy_id)
        .map(configuration => new DashboardConfiguration(configuration, state.scaledResultsData));
    },
    currentConfiguration(state: DashboardState): DashboardConfiguration | null {
      const configuration = state.configurationData?.find(configuration => state.currentConfigurationData?.configuration_policy_id === configuration.id);
      if (!configuration) {
        return null;
      }
      return new DashboardConfiguration(configuration, state.scaledResultsData);
    },
    scaledResults(state: DashboardState): DashboardScaledResults | null {
      if (!state.scaledResultsData || !state.scaledResultsMetadata) {
        return null;
      }
      return new DashboardScaledResults(state.scaledResultsData, state.scaledResultsMetadata);
    },
    selectedSpace(state: DashboardState): DashboardSpace | null {
      if (!state.selectedSpace) {
        return null;
      }
      return new DashboardSpace(state.selectedSpace);
    },
    isLoading(state: DashboardState): boolean {
      return state.isLoading;
    },
    isSavingSpace(state: DashboardState): boolean {
      return state.isSavingSpace;
    },
    currentSpace(state: DashboardState): ParticleOneCreateSpaceRequest {
      return state.spaceResponse ?? {};
    },
    parallelCoordinatePlot(state: DashboardState): string | null {
      return state.parallelCoordinatePlot;
    },
    backgroundInfectionInformation(state: DashboardState): DashboardBackgroundInfectionInformation | null {
      if (!state.backgroundInfectionData) {
        return null;
      }
      return new DashboardBackgroundInfectionInformation(state.backgroundInfectionData);
    }
  },
  actions: {
    async createSpace({ commit }, formData) {
      commit('updateIsSavingSpace', true);
      try {
        const response = await buildingApiService.createSpace(createSpaceRequest(formData));
        commit('updateSpaceResponse', response);
      } catch (error) {
        console.error('createSpace', error);
        throw error;
      } finally {
        commit('updateIsSavingSpace', false);
      }
    },
    async updateSpace({ commit, state }, formData) {
      commit('updateIsSavingSpace', true);
      try {
        const response = await buildingApiService.updateSpace(createSpaceRequest({ ...formData, id: state.spaceResponse.id }));
        commit('updateSpaceResponse', response);
      } catch (error) {
        console.error('updateSpace', error);
        throw error;
      } finally {
        commit('updateIsSavingSpace', false);
      }
    },
    setSpace({ commit }, space) {
      commit('updateSpaceResponse', { space: space });
    },
    async swapConfigurations({ commit }, { configuration_id, space_id, current_configuration_id }) {
      const response = await buildingApiService.swapConfigurations({ configuration_id, space_id, current_configuration_id });
      commit('updateCurrentConfiguration', response.current_configuration);
      commit('updateConfigurations', response.selected_configurations);
    },
    async getParallelCoordinatePlot({ commit }, spaceId) {
      commit('updateParallelCoordinatePlot', null);
      const response = await buildingApiService.getParallelCoordinatePlot(spaceId);
      commit('updateParallelCoordinatePlot', response.plot);
    },
    async deleteConfiguration({ commit }, { space_id, configuration_ids }) {
      const response = await buildingApiService.updateConfiguration({ space_id, configuration_ids });
      commit('updateConfigurations', response.configurations);
    },
    async addConfiguration({ commit }, { space_id, configuration_ids }) {
      const response = await buildingApiService.updateConfiguration({ space_id, configuration_ids });
      commit('updateConfigurations', response.configurations);
    },
    async deleteSpace({ commit }, { buildingId, spaceId }) {
      await buildingApiService.deleteSpace(buildingId, spaceId);
    },
  },
  mutations: {
    updateSpaceData(state: DashboardState, payload: { spaces: ParticleOneSpace[], selectedBuilding: ParticleOneBuilding }) {
      state.spaceData = payload.spaces;
      state.selectedBuilding = payload.selectedBuilding;
    },
    updateConfigurations(state: DashboardState, payload: ParticleOneConfiguration[]) {
      state.configurationData = payload;
    },
    updateCurrentConfiguration(state: DashboardState, payload: ParticleOneCurrentConfiguration) {
      state.currentConfigurationData = payload;
    },
    updateScaledResults(state: DashboardState, payload: ParticleOneScaledResults) {
      state.scaledResultsData = payload;
    },
    updateScaledResultsMetadata(state: DashboardState, payload: ParticleOneScaledResultsMetadata) {
      state.scaledResultsMetadata = payload;
    },
    updateSpace(state: DashboardState, payload: ParticleOneSpace) {
      state.selectedSpace = payload;
      state.selectedBuilding = payload?.building ?? null;
    },
    updateIsLoading(state: DashboardState, isLoading) {
      state.isLoading = isLoading;
    },
    updateIsSavingSpace(state: DashboardState, isSaving) {
      state.isSavingSpace = isSaving;
    },
    updateSpaceResponse(state: DashboardState, response: ParticleOneCreateSpaceResponse) {
      state.spaceResponse = response.space;
    },
    updateParallelCoordinatePlot(state: DashboardState, payload: string | null) {
      state.parallelCoordinatePlot = payload;
    },
    updateBackgroundInfectionInformation(state: DashboardState, payload: ParticleOneBackgroundInfectionInformation) {
      state.backgroundInfectionData = payload;
    }
  }
};

export default dashboard;

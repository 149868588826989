// @ts-ignore
import router from '@/router';
// @ts-ignore
import store from '@/store';
// @ts-ignore
import { TIMEOUT_ERROR } from '@/store/actions/response';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { AXIOS_DISCONNECTED_FROM_SERVER, AXIOS_UNAUTHENTICATED_STATUS_CODE, AXIOS_UNAUTHORIZED_STATUS_CODE } from './axios-constants';



function isTimeoutError(error: AxiosError) {
  return error.code === AXIOS_DISCONNECTED_FROM_SERVER || error.response?.status === 408;
}

async function logout(error?: Error) {
  await store.dispatch('logout');

  let routerObject;
  if (router.currentRoute.value.path !== '/') {
    routerObject = {
      path: '/login/',
      query: { redirect_from: window.location.hash.slice(1) }
    };
  } else {
    routerObject = {
      path: '/login/'
    };
  }
  router.push(routerObject);
  if (error) {
    await store.dispatch('setError', error);
  }
}

function accessTokenIsExpired(expires_on: string, threshold = 10 * 60 * 1000) {
  const expiryDate = new Date(expires_on);
  const now = new Date();
  return (expiryDate.getTime() - now.getTime()) < threshold;
}

export async function refreshAccessTokenBeforeExpiry() {
  if (store.getters.loggedIn && accessTokenIsExpired(store.getters.auth.expires_on)) {
    try {
      await store.dispatch('refreshToken');
      console.info('Successful automatic token refresh:', (new Date).toJSON());
    } catch (error) {
      console.error('Failed automatic token refresh. Logging out:', (new Date).toJSON());
      await logout(new Error('Your session has expired. Please log in again.'));
    }
  }
  return Promise.resolve();
}

export function addAccessTokenToRequest(request: AxiosRequestConfig) {
  const token = store.getters.auth.access_token;
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
}

export async function refreshAuthLogic(failedRequest: any) {
  try {
    await store.dispatch('refreshToken');
    console.info('Successful token refresh:', (new Date).toJSON());
    failedRequest.response.config.headers.Authorization = `Bearer ${store.getters.auth.access_token}`;
    return Promise.resolve();
  } catch (error) {
    console.error('Failed token refresh. Logging out:', (new Date).toJSON());
    await logout(new Error('Your session has expired. Please log in again.'));
    return Promise.reject(error);
  }
}

export async function handleTimeoutError(error: AxiosError) {
  if (isTimeoutError(error)) {
    await store.dispatch(TIMEOUT_ERROR, error);
  }
  return Promise.reject(error);
}

export function addTenantIdToRequest(request: AxiosRequestConfig) {
  request.headers['X-PARTICLEONE-TENANT-ID'] = router.currentRoute.value.params.tenantId;
  if (process.env.VUE_APP_ENVIRONMENT === 'local') {
    request.headers['X-MS-CLIENT-PRINCIPAL-NAME'] = store.getters.userEmail;
  }
  return request;
}

export async function handleUnauthorizedResponse(error: AxiosError) {
  if (error.response?.status === AXIOS_UNAUTHORIZED_STATUS_CODE) {
    await store.dispatch('setError', new Error('You do not have permission to complete this action right now. Please contact your administrator.'));
  }
  return Promise.reject(error);
}

export async function handleUnauthenticatedError(error: AxiosError) {
  if (error.response?.status === AXIOS_UNAUTHENTICATED_STATUS_CODE) {
    console.error('Unauthenticated. Logging out:', (new Date).toJSON());
    await logout(new Error('Your session has expired. Please log in again.'));
  }
  return Promise.reject(error);
}

export async function handleServerError(error: AxiosError) {
  if (error.response && error.response?.status >= 500) {
    console.error('Server error:', error.response);
    await store.dispatch('setError', new Error('Server Error - Please try again later.'));
  }
  return Promise.reject(error);
}

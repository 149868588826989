import { Module } from 'vuex';
import authenticationApiService from '@/network/services/authentication';
import authorizationApiService from '@/network/services/authorization';
import { ParticleOneTenant } from '@/network/models/ParticleOneTenant';
import { Tenant } from '@/models/user';

interface AuthenticationState {
  userData: any | null
  tenants: ParticleOneTenant[]
  isLoading: boolean,
  error: Error | null
}

function parseResponse(response: string | null) {
  if (response) {
    return JSON.parse(response);
  }
  return null;
}

function parseJwt(token: string) {
  const jsonPayload = decodeURIComponent(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(function(c) {
    // eslint-disable-next-line
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

const authentication: Module<any, any> = {
  state: {
    userData: parseResponse(localStorage.getItem('response')),
    tenants: [],
    isLoading: false,
    error: null
  } as AuthenticationState,
  getters: {
    loggedIn(state: AuthenticationState): boolean {
      return state.userData != null;
    },
    auth(state: AuthenticationState) {
      const userData = state.userData?.[0];
      return {
        access_token: localStorage.getItem('token') ?? userData?.access_token,
        expires_on: userData?.expires_on,
      };
    },
    userEmail(state: AuthenticationState, getters: any) {
      const token = getters.auth.access_token;
      if (token) {
        return parseJwt(token).unique_name;
      }
      return null;
    },
    tenants(state: AuthenticationState): Tenant[] | null {
      return state.tenants?.map(tenant => new Tenant(tenant));
    },
    authIsLoading(state: AuthenticationState): boolean {
      return state.isLoading;
    },
    errorMessage(state: AuthenticationState): string | null {
      if (state.error) {
        return state.error.message;
      }
      return null;
    }
  },
  actions: {
    async login({ commit }) {
      commit('updateIsLoading', true);
      const response = await authenticationApiService.getUserData();
      commit('login', response);
      commit('updateIsLoading', false);
    },
    async logout({ commit }) {
      commit('logout');
    },
    async authorize({ commit }) {
      commit('updateIsLoading', true);
      const response = await authorizationApiService.getAllTenants();
      commit('authorize', response.tenants);
      commit('updateIsLoading', false);
    },
    async setError({ commit }, error: Error) {
      commit('setError', error);
    },
    async refreshToken({ commit }) {
      const refreshResponse = await authenticationApiService.refreshToken();
      if (!refreshResponse.ok) {
        throw new Error(refreshResponse.statusText);
      }
      const response = await authenticationApiService.getUserData();
      commit('login', response);
    },
    async getAllTenants({ commit }) {
      commit('authorize', []);
      const response = await authorizationApiService.getAllTenants();
      commit('authorize', response.tenants);
      return response.tenants;
    }
  },
  mutations: {
    login(state: AuthenticationState, payload: any) {
      state.userData = payload;
      localStorage.setItem('response', JSON.stringify(payload));
      localStorage.setItem('token', payload[0]['access_token']);
    },
    logout(state: AuthenticationState) {
      state.userData = null;
      localStorage.removeItem('response');
      localStorage.removeItem('token');
      localStorage.removeItem('tenantName');
      localStorage.removeItem('tenantId');
    },
    authorize(state: AuthenticationState, payload: ParticleOneTenant[]) {
      state.tenants = payload;
    },
    updateIsLoading(state: AuthenticationState, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state: AuthenticationState, error: Error) {
      state.error = error;
    }
  }
};

export default authentication;

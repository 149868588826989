import { ParticleOneUserPermission } from '@/network/models/ParticleOneUserPermission';

class User {
  userPermission: ParticleOneUserPermission

  get email(): string {
    return this.userPermission.email_address;
  }

  get name(): string {
    return this.userPermission.display_name;
  }

  get permission(): Permission {
    return Permission[this.userPermission.permission as keyof typeof Permission];
  }

  get permissionType(): string {
    return this.userPermission.permission_type;
  }

  get oid(): string {
    return this.userPermission.id.split('_:_')[1];
  }

  get isPolicyAdmin(): boolean {
    return this.permission === Permission.policy_admin;
  }

  get hasNoPermissions(): boolean {
    return this.userPermission.permission == null;
  }

  constructor(userPermission: ParticleOneUserPermission) {
    this.userPermission = userPermission;
  }
}

enum Permission {
  read = 'read',
  write = 'write',
  policy_admin = 'policy admin'
}

export {
  User,
  Permission
};